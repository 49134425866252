import React from "react"

import Icon from "../Icon"
import c3loader from "../../images/c3loader.png"
import "./styles.scss"

const Loader = () => {
    return (
        <div className="row justify-content-center">
            <div className="col-3 d-flex loader-container">
                <div className="loader">
                    <img src={c3loader} alt="" />
                    <Icon name="spinner" spin={true} />
                </div>
            </div>
        </div>
    )
}

export default Loader